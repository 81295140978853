import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer.js';
import { MeshPhongMaterial } from 'three/src/materials/MeshPhongMaterial.js';
import { Mesh } from 'three/src/objects/Mesh.js';
import { LoadingManager } from 'three/src/loaders/LoadingManager.js';
import { Scene } from 'three/src/scenes/Scene.js';
import { Color } from 'three/src/math/Color.js';
import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera.js';
import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera.js';
import { Vector3 } from 'three/src/math/Vector3.js';
import { AmbientLight, PointLight } from 'three';
import { BoxGeometry } from 'three/src/geometries/BoxGeometry.js';
import { SphereGeometry } from 'three/src/geometries/SphereGeometry.js';
import { BufferGeometry, LineBasicMaterial, Line } from 'three';
import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import DragControls from 'three-dragcontrols/';
import { Logger } from './logger.js';

let scene, camera, renderer, controls, screenObjects = [], dragControls;// Define these outside so they are available to all functions
let selectedObject = null;
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let oldCameraPosition = new THREE.Vector3();
let dotnetref;
const canvas = document.querySelector('#myCanvas');

// The time variable
var time = 0;
var timeIncrementor = 0.01;
var wave = [];
var maxIterations = 10;
var radii = [1, 30, 5, 10, 400];
var animationFrameId = null;
var audioContext = null;
var oscillators = null;
var frequency = 0;
var isPlaying = false;
var isDrawing = true;
var lastDotPosition = null;
export const FFT = {
    init: function (canvasId, dotnetreference) {
        const canvas = document.getElementById(canvasId);
        const { clientWidth: width, clientHeight: height } = canvas;
        dotnetref = dotnetreference;
        scene = new THREE.Scene();
        camera = new THREE.OrthographicCamera(
            window.innerWidth / - 2,
            window.innerWidth / 2,
            window.innerHeight / 2,
            window.innerHeight / - 2,
            - 500,
            1000
        );
        camera.position.z = -10;
        
        renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableRotate = false;
        // Set some options (optional)
        controls.enableZoom = true; // enable zooming
        controls.zoomSpeed = 1.0; // set zoom speed
        renderer.setClearColor(0xffffff, 1); 
        renderer.setSize(width, height);
    },
    setVariables: function (newRadii, newMaxIterations,freq, newTime) {
        radii = newRadii.split(',').map(Number);
        maxIterations = newMaxIterations;
        timeIncrementor = newTime;
        frequency = freq;
    },
    mapRadiusToFrequency: function (radius) {
        return frequency;

        let minRadius = Math.min(...radii);
        let maxRadius = Math.max(...radii);
        let minFrequency = 20;
        let maxFrequency = 20000;

        if (frequency !== null || frequency !== 0) {
            return frequency;
        }

        return ((radius - minRadius) / (maxRadius - minRadius)) * (maxFrequency - minFrequency) + minFrequency;
    },
    playSound: function () {
        if (isPlaying) {
            FFT.stopSound();
        }
        if (!Array.isArray(radii)) {
            console.error('radii is not an array:', radii);
            return;
        }

        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContext = new AudioContext();
        oscillators = radii.map(() => audioContext.createOscillator());

        for (let i = 0; i < oscillators.length; i++) {
            oscillators[i].type = 'sine';
            let frequency = FFT.mapRadiusToFrequency(radii[i]);
            oscillators[i].frequency.setValueAtTime(frequency, audioContext.currentTime);
            oscillators[i].connect(audioContext.destination);
            oscillators[i].start(audioContext.currentTime + time);
            isPlaying = true;
        }
    },
    stopSound: function () {
        if (oscillators) {
            for (let i = 0; i < oscillators.length; i++) {
                oscillators[i].stop();
            }
        }
    },
    animate: function () {
        // Clear existing objects
        for (var i = scene.children.length - 1; i >= 0; i--) {
            var obj = scene.children[i];
            // Add a check for the objectType property in the if condition
            if ((obj.userData.objectType === 'dot')) {
                obj.geometry.dispose();
                obj.material.dispose();
                scene.remove(obj);
            }
            if ((obj.userData.objectType === 'line')) {
                obj.geometry.dispose();
                obj.material.dispose();
                scene.remove(obj);
            }
            if ((obj.userData.objectType === 'circle')) {
                obj.geometry.dispose();
                obj.material.dispose();
                scene.remove(obj);
            }
            if ((obj.userData.objectType === 'wave')) {
                obj.geometry.dispose();
                obj.material.dispose();
                scene.remove(obj);
            }
        }

        let x = 0;
        let y = 0;

        // The offset to shift the circles to the left
        let offsetX = -800;

        

        for (let i = 0; i < maxIterations; i++) {
            let prevx = x + offsetX;
            let prevy = y;
            let n = i * 2 + 1;
            let radius = radii[i % radii.length];
            x += radius * Math.cos(n * time);
            y += radius * Math.sin(n * time);

            // Draw the circle
            let circleGeometry = new THREE.CircleGeometry(radius, 64);
            let circleMaterial = new THREE.LineBasicMaterial({ color: 'red' });
            let circle = new THREE.Mesh(circleGeometry, circleMaterial);
            circle.position.set(prevx, prevy, 0);
            circle.userData.objectType = 'circle';
            scene.add(circle);

            //create points for the radius line
            let points = [];
            points.push(new THREE.Vector3(prevx, prevy, 0));
            points.push(new THREE.Vector3(x + offsetX, y, 0));

            let lineMaterial = new THREE.LineBasicMaterial({ color: new THREE.Color('red'), linewidth: 1 });
            let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
            let line = new THREE.Line(lineGeometry, lineMaterial);
            line.userData.objectType = 'line';
            scene.add(line);
            if (i == maxIterations - 1 && isDrawing) {
                
            }
            // Create a line from the center to the circumference
            

            // Create a dot at the end of the line
            if (i == maxIterations - 1 && isDrawing) {
                let dotGeometry = new THREE.SphereGeometry(2, 32, 32); // Size of the dot
                let dotMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color('black') });
                let dot = new THREE.Mesh(dotGeometry, dotMaterial);
                dot.position.set(x + offsetX, y, 0);
                dot.userData.objectType = 'dot';
                scene.add(dot);

                if (lastDotPosition !== null) {
                    // Draw a line from the last dot to the current one
                    let points = [lastDotPosition, dot.position.clone()];
                    let lineMaterial = new THREE.LineBasicMaterial({ color: new THREE.Color('black'), linewidth: 2 });
                    let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
                    let line = new THREE.Line(lineGeometry, lineMaterial);
                    line.userData.objectType = 'dotline';
                    scene.add(line);
                }

                lastDotPosition = dot.position.clone(); // Store the current dot's position for the next function call
            }
        }

        wave.unshift(y);
        if (wave.length > 1500) {
            wave.pop();
        }
        var vertices = [];
        for (let i = 0; i < wave.length; i++) {
            vertices.push(new THREE.Vector3(i + 300, wave[i], 0));
        }

        var waveMaterial = new THREE.LineBasicMaterial({ color: new THREE.Color('blue'), linewidth: 2 });
        var waveGeometry = new THREE.BufferGeometry().setFromPoints(vertices);
        var waveLine = new THREE.Line(waveGeometry, waveMaterial);
        waveLine.userData.objectType = 'wave';
        scene.add(waveLine);
        
        time += timeIncrementor;
        renderer.render(scene, camera);
        controls.update();
        animationFrameId = requestAnimationFrame(FFT.animate);
    },
    runAnimation: function () {
        if (animationFrameId === null) {
            FFT.animate();
        }
    },
    stopAnimation: function () {
        if (animationFrameId !== null) {
            for (var i = scene.children.length - 1; i >= 0; i--) {
                var obj = scene.children[i];

                // Check if the object has geometry or material properties before trying to dispose them
                if (obj.geometry) {
                    obj.geometry.dispose();
                }
                if (obj.material) {
                    obj.material.dispose();
                }
                scene.remove(obj);
            }
            // Remove all children from the scene
            while (scene.children.length > 0) {
                scene.remove(scene.children[0]);
            }
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
        }
    }
}