import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer.js';
import { MeshPhongMaterial } from 'three/src/materials/MeshPhongMaterial.js';
import { Mesh } from 'three/src/objects/Mesh.js';
import { LoadingManager } from 'three/src/loaders/LoadingManager.js';
import { Scene } from 'three/src/scenes/Scene.js';
import { Color } from 'three/src/math/Color.js';
import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera.js';
import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera.js';
import { Vector3 } from 'three/src/math/Vector3.js';
import { AmbientLight, PointLight } from 'three';
import { BoxGeometry } from 'three/src/geometries/BoxGeometry.js';
import { SphereGeometry } from 'three/src/geometries/SphereGeometry.js';

import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import  DragControls  from 'three-dragcontrols/';
import { Logger } from './logger.js';
import { FFT } from './fft.js';
import { slp } from './spline.js';

let scene, camera, renderer, controls, screenObjects = [], dragControls;// Define these outside so they are available to all functions
let selectedObject = null;
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let oldCameraPosition = new THREE.Vector3();
let dotnetref;
const canvas = document.querySelector('#myCanvas');

window.slp = slp;
window.logger = Logger;
window.fft = FFT;
window.threeFunctions = {
    clearScene: () => {
        while (scene.children.length > 0) {
            var object = scene.children[0];
            scene.remove(object);
        }
    },
    
    setCameraPosition: (x, y, z) => {
        camera.position.set(x, y, z);
    },
    centerCamera: () => {
        //let xSum = 0, ySum = 0, zSum = 0, totalObjects = 0;

        //scene.traverse(function (child) {
        //    if (child.position) {  // Check if the object has a position
        //        xSum += child.position.x;
        //        ySum += child.position.y;
        //        zSum += child.position.z;

        //        totalObjects++;
        //    }
        //});

        //if (totalObjects === 0) return;

        //let centroid = new THREE.Vector3(xSum / totalObjects, ySum / totalObjects, zSum / totalObjects);

        //camera.position.x = centroid.x -50;
        //camera.position.y = centroid.y;
        //camera.position.z = centroid.z; // Adjust the camera's z-position to get a better view
        //camera.zoom = 1;
        //camera.updateProjectionMatrix();
        //camera.rotation.y += Math.PI; 
        ////controls.target = new THREE.Vector3(x, y, z);
        //camera.lookAt(centroid);
    },
    initialize: (canvasId, cameraType,dotnetRef) => {
        const canvas = document.getElementById(canvasId);
        const { clientWidth: width, clientHeight: height } = canvas;
        dotnetref = dotnetRef;
        // Initialize renderer, scene and camera here
        renderer = new WebGLRenderer({ canvas, antialias: true });
        renderer.setSize(width, height);
        renderer.domElement.addEventListener('click', function (event) {
            // calculate mouse position in normalized device coordinates
            // (-1 to +1) for both components
            //const width = canvas.clientWidth;
            //const height = canvas.clientHeight;
            //mouse.x = (event.clientX / width) * 2 - 1;
            //mouse.y = - (event.clientY / height) * 2 + 1;

            let containerRect = renderer.domElement.getBoundingClientRect();
            mouse.x = ((event.clientX - containerRect.left) / containerRect.width) * 2 - 1;
            mouse.y = -((event.clientY - containerRect.top) / containerRect.height) * 2 + 1;
            // update the picking ray with the camera and mouse position
            raycaster.setFromCamera(mouse, camera);

            // calculate objects intersecting the picking ray
            var intersects = raycaster.intersectObjects(scene.children, true);
            console.log(intersects);
            for (var i = 0; i < intersects.length; i++) {
                console.log(intersects[i]);
                if (intersects[i].object.type === "Mesh") {  // We only want to select Meshes
                    selectedObject = intersects[i].object;
                    break;
                }
            }
        }, false);

        scene = new Scene();
        scene.background = new Color(0xffffff); // white color
        if (cameraType === 'perspective') {
            camera = new PerspectiveCamera(75, width / height, 0.1, 1000);
        } else {
            camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);
        }
        camera.position.z = 100;
        //camera.position.z = 500;  // increase this if the camera is too close
        //camera.lookAt(scene.position);  // make sure the camera is looking at the center of the scene

        controls = new OrbitControls(camera, renderer.domElement);

        // Add lights
        const ambientLight = new AmbientLight(0xffffff); // soft white light
        scene.add(ambientLight);

        const pointLight = new PointLight(0xffffff, 1, 100);
        pointLight.position.set(50, 50, 50);
        scene.add(pointLight);
                        
        animate();
    },
    createTextGeometry: (text, position, color, fontPath,fontSize,fontHeight,fontCurveSegments,isBevel,bevelThickness,bevelSize,bevelOffset,bevelSegments,isDraggable) => {
        //Manager from ThreeJs to track a loader and its status
        //var manager = new LoadingManager();
        //Loader for Obj from Three.js
        
        const loader = new FontLoader();

        // You need to have the font file in your public directory
        loader.load(fontPath, function (font) {
            console.log('font loaded', font);
            const geometry = new TextGeometry(text, {
                font: font,
                size: fontSize,//10,  // decrease this if the text is too large
                height: fontHeight,//5,
                curveSegments: fontCurveSegments,//3,
                bevelEnabled: isBevel,//true,
                bevelThickness: bevelThickness,//0.10,  // decrease this if the bevel is too thick
                bevelSize: bevelSize,//0.30,  // decrease this if the bevel is too large
                bevelOffset: bevelOffset,//0.1,
                bevelSegments: bevelSegments//4
            });
            
            const hexColor = convertColorToHex(color);
            if (hexColor === null) {
                console.error('Invalid color string');
                // Return or do some error handling according to your needs
                return;
            }
            
            var newColor = new Color(hexColor);
            //const material = new MeshPhongMaterial({ color: newColor });
            const material = new MeshBasicMaterial({ color: newColor });
            const mesh = new Mesh(geometry, material);
            mesh.position.set(position.x, position.y, position.z);
            console.log('text position', mesh.position);
            scene.add(mesh);  // 'scene' is now accessible here
            screenObjects.push(mesh);

            dragControls = new DragControls(scene.children, camera, renderer.domElement);

            controls.addEventListener('dragstart', function (event) {
                event.object.material.color.set('#f00');
            });

            controls.addEventListener('dragend', function (event) {
                event.object.material.color.set('#fff');
            });
            dragControls.enabled = isDraggable; // disable dragging by default

            
        },
            undefined,  // onProgress callback, not needed
            function (error) {
                console.error('error loading font', error);
        });
        
        animate(); 
    },
    createBallGeometry: (balls) => {
        balls.forEach(ball => {
            const geometry = new SphereGeometry(ball.size, 16, 16);
            const hexColor = convertColorToHex(ball.ballColor);
            if (hexColor === null) {
                console.error('Invalid color string');
                // Return or do some error handling according to your needs
                return;
            }
            var newColor = new Color(hexColor);
            const material = new MeshBasicMaterial({ color: newColor });
            const sphere = new Mesh(geometry, material);
            sphere.position.set(ball.position[0], ball.position[1], ball.position[2]);

            // set name as color for future reference
            sphere.name = ball.color;
            scene.add(sphere);

            // Make the sphere draggable if IsDraggable is true
            if (ball.isDraggable) {
                const controls = new DragControls([sphere], camera, renderer.domElement);
                controls.addEventListener('drag', render);
            }
            
        });
    },
    findEdges: () => {
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                let position = child.position;
                minX = Math.min(position.x, minX);
                maxX = Math.max(position.x, maxX);
                minY = Math.min(position.y, minY);
                maxY = Math.max(position.y, maxY);
            }
        });

        return {
            lowerLeft: { x: minX, y: minY },
            lowerRight: { x: maxX, y: minY },
            upperLeft: { x: minX, y: maxY },
            upperRight: { x: maxX, y: maxY }
        };
    },
    changeBackground: (color) => {
        // code to change the background of the scene
        scene.background = new Color(color); // 'scene' is now accessible here
    },
    exportGLTF: () => {
        const exporter = new GLTFExporter();

        exporter.parse(scene, function (gltf) {
            // Convert the GLTF object to a JSON string
            const output = JSON.stringify(gltf, null, 2);

            // Create a blob from the output string
            const blob = new Blob([output], { type: 'text/plain' });

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href to a URL created from the blob
            link.href = URL.createObjectURL(blob);

            // Set the download attribute to specify the name of the downloaded file
            link.download = 'scene.gltf';

            // Add the link to the document
            document.body.appendChild(link);

            // Simulate clicking the link
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
        }, { binary: false });
    },
    enableDragging: () => {
        dragControls.enabled = true;
    },
    disableDragging: () => {
        dragControls.enabled = false;
    }
}

function animate() {
    requestAnimationFrame(animate);

    const epsilon = 0.1;
        // Check if camera position has changed
    if (!camera.position.equals(oldCameraPosition) &&
        !camera.position.clone().sub(oldCameraPosition).lengthSq() < epsilon ** 2) {
        // Camera position has changed
        oldCameraPosition.copy(camera.position);

        // Call the function in Blazor with the new camera position
        dotnetref.invokeMethodAsync('CameraPositionChanged',
            camera.position.x, camera.position.y, camera.position.z);
    }

    controls.update(); 
    renderer.render(scene, camera);
}

window.addEventListener('resize', () => {
    if (canvas === undefined || canvas == null) return;
    if (canvas.clientWidth == null) return;
    if (canvas.clientWidth === undefined || canvas.clientHeight === undefined) return;
    if (canvas.clientWidth === 0 || canvas.clientHeight === 0) return;
    
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
});
window.addEventListener('keydown', function (event) {
    if (selectedObject) {
        var moveDistance = 1;  // Adjust this value to change the distance the object moves with each key press

        switch (event.key) {
            case 'w':  // Move up
                selectedObject.position.y += moveDistance;
                break;
            case 's':  // Move down
                selectedObject.position.y -= moveDistance;
                break;
            case 'a':  // Move left
                selectedObject.position.x -= moveDistance;
                break;
            case 'd':  // Move right
                selectedObject.position.x += moveDistance;
                break;
            case 'q':  // Move forward (towards camera)
                selectedObject.position.z += moveDistance;
                break;
            case 'e':  // Move backward (away from camera)
                selectedObject.position.z -= moveDistance;
                break;
        }

        // Notify Blazor that the object has been moved
        //DotNet.invokeMethodAsync('YourBlazorProjectName', 'NotifyObjectMoved', selectedObject.uuid, selectedObject.position.x, selectedObject.position.y, selectedObject.position.z);
    }
});

function convertColorToHex(colorString) {
    // If it's an hexadecimal string, remove alpha if present
    if (colorString.startsWith('#')) {
        return colorString.substring(0, 7); // Remove alpha channel
    }


    const isRgb = /^rgb\((\d+), (\d+), (\d+)\)$/;
    const rgbMatch = colorString.match(isRgb);
    if (rgbMatch) {
        const r = parseInt(rgbMatch[1]);
        const g = parseInt(rgbMatch[2]);
        const b = parseInt(rgbMatch[3]);
        const hex = ((r << 16) | (g << 8) | b).toString(16);
        const paddedHex = hex.padStart(6, '0');
        return parseInt(`0x${paddedHex}FF`);
    }

    const isHsl = /^hsl\((\d+), (\d+)\%, (\d+)\%\)$/;
    const hslMatch = colorString.match(isHsl);
    if (hslMatch) {
        const h = parseInt(hslMatch[1]);
        const s = parseInt(hslMatch[2]) / 100;
        const l = parseInt(hslMatch[3]) / 100;

        const c = (1 - Math.abs(2 * l - 1)) * s;
        const hp = h / 60;
        const x = c * (1 - Math.abs(hp % 2 - 1));
        let r, g, b;

        if (hp < 1) { [r, g, b] = [c, x, 0]; }
        else if (hp < 2) { [r, g, b] = [x, c, 0]; }
        else if (hp < 3) { [r, g, b] = [0, c, x]; }
        else if (hp < 4) { [r, g, b] = [0, x, c]; }
        else if (hp < 5) { [r, g, b] = [x, 0, c]; }
        else { [r, g, b] = [c, 0, x]; }

        const m = l - c / 2;
        const hexR = Math.round((r + m) * 255).toString(16).padStart(2, '0');
        const hexG = Math.round((g + m) * 255).toString(16).padStart(2, '0');
        const hexB = Math.round((b + m) * 255).toString(16).padStart(2, '0');

        return parseInt(`0x${hexR}${hexG}${hexB}FF`);
    }

    const colorParts = colorString.match(/([A-Za-z0-9]{2})/g);

    if (colorParts.length < 3) {
        return null;
    }

    let alphaHex;
    if (colorParts.length === 3) {
        // If the color string only has 3 parts, add a default alpha value
        alphaHex = 'FF';
    } else {
        // If the color string has 4 parts, extract the alpha value from the string
        alphaHex = colorParts[3];
    }

    const hexValue = '0x' + colorParts[0] + colorParts[1] + colorParts[2] + alphaHex;
    return parseInt(hexValue);
}