import { Application } from '@splinetool/runtime';

export const slp = {
    init: (canvasId, sceneUrl) => {
        const canvas = document.getElementById(canvasId);
        const app = new Application(canvas);
        app.load("https://prod.spline.design/lZWqO1qDUIfXKIeX/scene.splinecode");

        // Customize the loaded scene
        app.addEventListener('load', () => {
            const scene = app.scene;

            // Get the main camera
            const camera = scene.cameras[0];

            // Set initial camera position and target
            camera.position.set(-10, 10, 10);
            camera.target.set(0, 0, 0);

            // Create a nice animation loop
            let angle = 0;
            const radius = 15;
            const speed = 0.002;

            const updateCamera = () => {
                angle += speed;
                const x = Math.cos(angle) * radius;
                const z = Math.sin(angle) * radius;
                camera.position.set(x, 10, z);
                camera.target.set(0, 0, 0);
            };

            // Animation loop
            const animate = () => {
                updateCamera();
                requestAnimationFrame(animate);
            };

            animate();

            // Enable orbit controls for interactive camera movement
            const controls = new OrbitControls(camera, canvas);
            controls.enableDamping = true;
            controls.dampingFactor = 0.05;
            controls.screenSpacePanning = false;
            controls.minDistance = 5;
            controls.maxDistance = 50;
            controls.maxPolarAngle = Math.PI / 2;
            controls.target.set(0, 0, 0);
            controls.update();

            // Render loop
            const render = () => {
                controls.update();
                app.render();
                requestAnimationFrame(render);
            };

            render();
        });
    },
}; 